import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Harus di ganti Incididunt consequat magna magna ullamco non proident mollit consequat consectetur do elit mollit aliqua. Sit esse qui cupidatat aliquip proident sit ex. Lorem et occaecat officia consectetur aliqua irure deserunt in Lorem cupidatat:`}</p>
    </PageDescription>
    <h2>{`How and Why?`}</h2>
    <ol>
      <li parentName="ol">{`Having our design principles to adapt to everyone’s needs and constraints.`}</li>
      <li parentName="ol">{`Became a good citizen on any platforms by adopting ideas and platforms languages as guidance to build a suite of great products that engendered trustworthiness and familiarity in every experience.`}</li>
      <li parentName="ol">{`Maintaining coherence as a means to an end of great experience, forgo the belief that everything should be, look, or works the same in the name of consistency.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      